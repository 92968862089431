<template>
  <div
    class="nav-section"
    :style="{
      backgroundImage: `url(${require('../assets/backgrounds/Menu.jpg')})`, 
    }"
    style="backdrop-filter: blur(20px);"
  >
    <div class="nav-content">
      <div class="menu-content">
        <h3 class="subtitle">Menu</h3>
        <div class="nav-links">
        <a class="nav-link" @click="goToSlide(0)">HOME</a>
        <a class="nav-link" @click="goToSlide(1)">STORYBOARD</a>
        <a class="nav-link" @click="goToSlide(2)">SPACEMAN</a>
        <a class="nav-link" @click="goToSlide(3)">COLLABORATIONS</a>
        <a class="nav-link" @click="goToSlide(4)">ROADMAP</a>
        <a class="nav-link" @click="goToSlide(5)">TEAM</a>
        <a class="nav-link" @click="goToSlide(6)">CONTACT US</a>
        </div>
      </div>
      
      <div class="contact-content">
        <div class="contact-item"><h3 class="subtitle">CONTACT US</h3></div>
        <div class="contact-item">
          <div @click="openTwitter()">
            <img
              class="contact-picture"
              src="../assets/icons/Twitter.png"
            />
          </div>
          <div @click="openDiscord()">
            <img
              class="contact-picture"
              src="../assets/icons/Discord.png"
            />
          </div>
          <div>
            <a style="padding: 0" href="mailto:secretspacemannfts@gmail.com">
              <img
                class="contact-picture"
                src="../assets/icons/Mail.png"
              />
            </a>
          </div>
        </div>
        <div class="contact-item">
          <p class="contact-subtext">
            Secret spaceman | 2022 | made with love by 10+ enthusiasts
          </p>
        </div>
        <div>
          <p class="contact-subtext">Copyright © 2022 SPACEMAN</p>
        </div>
        <div class="logo-container">
            <div class="logo">
              <img src="../assets/icons/SpacemanLogoWhite.png" />
            </div>
            <div class="badge">
              <img src="../assets/icons/Badge.png" />
            </div>
          </div>
      
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navMenu",
  methods: {
    goToSlide(index) {
      var emitter = require("tiny-emitter/instance");
      emitter.emit("close-nav");
      emitter.emit("nav-slide", index);
    },
    openTwitter() {
      window.open("https://twitter.com/secret_spaceman");
    },
    openDiscord() {
      window.open("https://discord.gg/rfKsAYpA6t");
    },
    openStashh() {
      window.open("https://stashh.io");
    },
  },
};
</script>

<style scoped>
.nav-section {
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.menu-content {
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 2px solid #f24633;
}

.logo-container {
  display: flex;
    padding-top: 20px;
    justify-content: center;
    flex-direction: row;
}

.logo img, .badge img {
  height: 6vh;
  padding: 20px;
}

.nav-links{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #f24633;
  margin: 0 5vh;
  padding: 2vh;
}

.nav-link {
  font-size: 2vh;
  color: #ffffff;
  letter-spacing: 0;
  font-family: Ubuntu;
  padding: 1vh;
}

.nav-link:hover {
  color: #f24633;
  cursor: pointer;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .nav-content {
  flex-direction: row;
  align-items: center;
}

.menu-content {
  border-bottom: 0px;
  border-right: 2px solid #f24633;
  flex: 35%;
}

.contact-content {
  flex: 65%;
}

.nav-link {
  font-size: 2.5vh;
  padding: 10px;
}

.logo img, .badge img {
  height: 8vh;
}
}
@media (min-device-width: 1920px) {

.nav-link {
  font-size: 27px;
  padding: 15px;
}

.logo img, .badge img {
  max-height: 86px;
}
}

@media (max-device-height: 480px) {
  .nav-content {
  flex-direction: row;
  align-items: center;
}

.menu-content {
  border-bottom: 0px;
  border-right: 2px solid #f24633;
  flex: 35%;
}

.contact-content {
  flex: 65%;
}

.nav-link {
  font-size: 2.5vh;
}
}

</style>
