<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Team.jpg')})`,
    }"
  >
    <div class="slide-container">
      <div class="team-items">
        <div><h3 class="subtitle">TEAM</h3></div>
        <div class="team-container">
          <div
            class="member-container link"
            @click="goToTwitter('spaceman_hp')"
          >
            <img class="member-dp" src="../../assets/icons/DP.png" />
            <h3 class="member-name">SPACEMAN</h3>
            <p class="member-position">Founder & CEO</p>
            <p class="member-position">France</p>
          </div>
          <div class="member-container">
            <img class="member-dp" src="../../assets/icons/DP.png" />
            <h3 class="member-name">DR.INDHU</h3>
            <p class="member-position">Project Mentor</p>
            <p class="member-position">USA</p>
          </div>
          <div
            class="member-container link"
            @click="goToTwitter('dummy_ssnft')"
          >
            <img class="member-dp" src="../../assets/icons/DP.png" />
            <h3 class="member-name">DUMMY.DESIGNER</h3>
            <p class="member-position">Arts & Creatives</p>
            <p class="member-position">India</p>
          </div>
          <div class="member-container link" @click="goToTwitter('neo_ssnft')">
            <img class="member-dp" src="../../assets/icons/DP.png" />
            <h3 class="member-name">NEO</h3>
            <p class="member-position">Experience & Publicity</p>
            <p class="member-position">India</p>
          </div>
          <div class="member-container">
            <img class="member-dp" src="../../assets/icons/DP.png" />
            <h3 class="member-name">MKR</h3>
            <p class="member-position">Back-end Developer</p>
            <p class="member-position">India</p>
          </div>
          <div class="member-container">
            <img class="member-dp" src="../../assets/icons/DP.png" />
            <h3 class="member-name">RIYA</h3>
            <p class="member-position">Front-end Developer</p>
            <p class="member-position">France</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamSlide",
  methods: {
    goToTwitter(name) {
      let link = "https://twitter.com/" + name;
      window.open(link);
    },
  },
};
</script>

<style>
.team-items {
  display: flex;
  flex-direction: column;
}

.member-dp {
  margin: 10px;
  border: 1px solid white;
  border-radius: 50%;
  height: 5vh;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 80vw;
}

.member-container {
  margin: 10px;
  text-align: center;
  font-size: 30px;
  background: rgba(196, 196, 196, 0.01);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  flex: 1 0 30%;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.member-container a {
  padding: 0;
  text-decoration: none;
}

.member-container.link:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.member-name {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vh;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.member-position {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vh;
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .member-name {
    font-size: 2vh;
  }

  .member-position {
    font-size: 1.7vh;
  }

  .member-container {
    flex: 1 0 25%;
  }

  .member-dp {
    height: 7vh;
  }
}

@media (min-device-width: 1920px) {
  .member-dp {
    max-height: 80px;
    margin: 15px;
  }

  .member-name {
    font-size: 21px;
  }

  .member-position {
    font-size: 18px;
  }
}

@media (max-device-height: 480px) {
  .member-dp {
    display: none;
  }

  .member-name {
    font-size: 2.5vh;
    margin: 10px;
  }

  .member-position {
    font-size: 2vh;
  }
}
</style>
