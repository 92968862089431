<template>
  <div class="header-container">
    <div className="spaceman-logo-container">
      <img
        class="spaceman-logo"
        src="../assets/icons/SpacemanLogo.png"
        alt="Spaceman Logo"
      />
    </div>
    <div class="header-items">
      <div class="hamburger" @click="openNav()">
        <img src="../assets/icons/Hamburger.png" />
      </div>

      <div class="marketplace" @click="goToMarketplace">MARKETPLACE</div>
      <div class="claim-button" @click="goToMySpaceman">MY SPACEMAN</div>
    </div>
    <iframe ref="myModal" class="claim-modal" src="/claim" frameborder="0px" />
    <div ref="myNav" class="nav-overlay">
      <a class="closehamburger" @click="closeNav()"
        ><img src="../assets/icons/CloseBtn.png"
      /></a>
      <navMenu />
    </div>
  </div>
</template>

<script>
import navMenu from "./NavigationMenu.vue";

export default {
  name: "appHeader",
  computed: {
    emitter() {
      return require("tiny-emitter/instance");
    },
  },
  mounted() {
    this.emitter.on("close-nav", () => {
      this.closeNav();
    });
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== process.env.BASE_URL) return;
        else if (event.data === "CLOSE-MODAL") {
          var claimModal = this.$refs.myModal;
          claimModal.style.display = "none";
        }
      },
      false
    );
  },
  methods: {
    goToMarketplace() {
      window.open(process.env.VUE_APP_MARKETPLACE_URL);
    },
    goToMySpaceman() {
      window.open(process.env.VUE_APP_SPACEMAN_URL + "/myspaceman");
    },
    showModal() {
      var claimModal = this.$refs.myModal;
      claimModal.style.display = "block";
    },
    closeNav() {
      this.emitter.emit("display-arrows");
      this.$refs.myNav.style.width = "0%";
    },
    openNav() {
      this.emitter.emit("hide-arrows");
      this.$refs.myNav.style.width = "100%";
    },
  },
  components: {
    navMenu,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Ubuntu:100,300,400,500,700,900");

.header-container {
  width: 100vw;
  height: 50px;
  z-index: 2;
  position: sticky;
}

.spaceman-logo-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -8vh;
}

.hamburger {
  display: flex;
  margin-left: 5vw;
  flex: 1 0 auto;
}

.hamburger img {
  height: 5vh;
}

.hamburger img:hover {
  background: #f24633;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;
}

.spaceman-logo {
  height: 10vh;
}

.nav-overlay .closehamburger img:hover {
  padding: 4px;
  background: #f24633;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;
}

.nav-overlay .closehamburger {
  position: absolute;
  margin-left: 5vw;
  margin-top: 2vw;
  z-index: 55;
}

.nav-overlay .closehamburger img {
  height: 5vh;
}

.marketplace {
  display: none;
  right: 240px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
}

.marketplace:hover {
  padding-bottom: 5px;
  border-bottom: 1px solid #f24633;
  cursor: pointer;
  transition: 0.2s;
}

.claim-button {
  display: none;
  margin-right: 5vw;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #ffffff;
  border-radius: 4px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 900;
  font-size: 1.7vh;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.claim-button:hover {
  background: #f24633;
  color: #ffffff;
  cursor: pointer;
}

.nav-overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.nav-overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.nav-overlay a:hover,
.nav-overlay a:focus {
  color: #f1f1f1;
}

.claim-modal {
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

@media screen and (min-device-width: 961px) {
  .spaceman-logo {
    height: 12vh;
  }
}
@media screen and (min-device-width: 1025px) {
  .claim-button {
    display: flex;
    font-size: 1.8vh;
    border-radius: 3px;
    padding: 10px;
  }
}

@media screen and (min-device-width: 1025px) and (orientation: landscape) {
  .marketplace {
    display: flex;
    font-size: 1.8vh;
    border-radius: 3px;
    padding: 10px 20px;
  }
}
@media (min-device-width: 1920px) {
  .claim-button,
  .marketplace {
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
  }

  .marketplace {
    margin: 0 50px;
  }

  .header-items {
    margin-top: -86px;
  }

  .marketplace {
    right: 420px;
  }

  .hamburger img {
    max-height: 54px;
  }
  .spaceman-logo {
    max-height: 130px;
  }
}
@media screen and (max-device-height: 480px) {
  .spaceman-logo {
    height: 18vh;
  }
  .hamburger img {
    height: 8vh;
  }
  .claim-button {
    font-size: 3vh;
  }
}
</style>
