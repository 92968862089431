<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Home.jpg')})`,
    }"
  >
    <div class="slide-container">
      <div>
        <h3 class="home-title">Spaceman NFTs</h3>
        <p class="home-subtext">
          100 Handmade NFTs, 3D rendered with out-of-the-world perks
        </p>
        <button class="whitepaper-button" @click.prevent="goToWhitepaper()">
          VIEW WHITEPAPER
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeSlide",
  methods: {
    goToWhitepaper() {
      window.open("https://drive.google.com/file/d/10ixsQT00W1n77v_B0QHYkU4eUDmKlZvt/view?usp=drivesdk");
    },
  },
};
</script>

<style>

.whitepaper-button {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 1.7vh;
  border: 1px solid #ffffff;
  padding: 5px;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: transparent;
  margin-top: 5vh;
}

.whitepaper-button:hover {
  cursor: pointer;
  background-color: #f24633;
}

.home-title {
  font-family: Expansiva;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5vh;
  color: #ffffff;
  padding-top: 40vh;
  margin-bottom: 0px;
}

.home-subtext {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vh;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}

@media screen and (min-device-width: 1025px) and (orientation: landscape) {
  .whitepaper-button {
    font-size: 1.8vh;
    border-radius: 3px;
    padding: 10px;
  }

  .home-title {
    font-size: 5vh;
  }

  .home-subtext {
    font-size: 1.5vh;
  }
}
@media (min-device-width: 1920px) {
  .whitepaper-button {
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
  }

  .home-title {
    font-size: 54px;
  }

  .home-subtext {
    font-size: 14px;
  }
}

@media screen and (max-device-height: 480px) {
  .whitepaper-button {
    font-size: 3vh;
  }

  .home-title {
    font-size: 7vh;
  }

  .home-subtext {
    font-size: 2vh;
  }
}
</style>
