<template>
  <div
    class="modal-content"
    :style="{
      backgroundImage: `url(${require('../assets/backgrounds/Claim.jpg')})`,
    }"
  >
    <h3 class="subtitle">Claim Reward</h3>
    <p class="claim-subtext">Launching after initial sales...</p>
    <div class="close-button">CLOSE</div>
  </div>
</template>

<script>
export default {
  name: "claim",
  mounted() {
    const btn = document.querySelector(".close-button");

    btn.addEventListener("click", () => {
      if (window.parent) {
        window.parent.postMessage("CLOSE-MODAL", "*");
      }
    });
  },
};
</script>

<style>
.modal-content {
  background-color: #000000;
  margin: auto;
  border: 1px solid #ffffff;
  border-radius: 4px;
  width: 80vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.claim-subtext {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vh;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  flex: 1 1 auto;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #ffffff;
  border-radius: 4px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 900;
  font-size: 1.7vh;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 3vh;
}

.close-button:hover,
.close-button:focus {
  background-color: #f24633;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (min-device-width: 1025px) and (orientation: landscape) {
  .close-button {
    font-size: 1.8vh;
    border-radius: 3px;
    padding: 10px;
  }
  .claim-subtext {
    font-size: 1.5vh;
  }
}
@media (min-device-width: 1920px) {
  .close-button {
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
  }
  .claim-subtext {
    font-size: 14px;
  }
}

@media screen and (max-device-height: 480px) {
  .close-button {
    font-size: 3vh;
  }
  .claim-subtext {
    font-size: 2vh;
  }
}
</style>
