<template>
  <div>
    <a class="up-arrow" v-bind:class="{ disable: dUpArrow }" @click="slidePrev"
      ><img src="../assets/icons/UpArrow.png"
    /></a>
    <a
      class="down-arrow"
      v-bind:class="{ disable: dDownArrow }"
      @click="slideNext"
      ><img src="../assets/icons/DownArrow.png"
    /></a>
    <a
      class="home-arrow"
      v-bind:class="{ disable: dHomeArrow }"
      @click="goToHome"
      ><img src="../assets/icons/HomeArrow.png"
    /></a>
  </div>
</template>

<script>
export default {
  name: "appFooter",
  data() {
    return {
      dUpArrow: true,
      dDownArrow: false,
      dHomeArrow: true,
      currentIndex: 0,
    };
  },
  computed: {
    emitter() {
      return require("tiny-emitter/instance");
    },
  },
  mounted() {
    this.emitter.on("hide-arrows", () => {
      this.dUpArrow = true;
      this.dDownArrow = true;
      this.dHomeArrow = true;
    });
    this.emitter.on("display-arrows", () => {
      this.updateArrows(this.currentIndex);
    });
    this.emitter.on("slideChange", (index) => {
      this.currentIndex = index;
      this.updateArrows(index);
    });
  },
  methods: {
    slidePrev() {
      this.emitter.emit("slidePrev");
    },
    slideNext() {
      this.emitter.emit("slideNext");
    },
    goToHome() {
      this.emitter.emit("goToHome");
    },
    updateArrows(index) {
      this.dUpArrow = index == 0 ? true : false;
      this.dDownArrow = index == 6 ? true : false;
      this.dHomeArrow = index != 6 ? true : false;
    },
  },
};
</script>

<style>
.up-arrow {
  z-index: 2;
  position: fixed;
  bottom: 15vh;
  right: 3vw;
}

.up-arrow.disable,
.down-arrow.disable,
.home-arrow.disable {
  display: none;
}

.down-arrow,
.home-arrow {
  z-index: 2;
  position: fixed;
  bottom: 8vh;
  right: 3vw;
}

.down-arrow img,
.up-arrow img
 {
  display: none;
  height: 4vh;
  padding: 4px;
  transition: 0.2s;
}

.home-arrow img{
  display: none;
  padding: 4px;
  transition: 0.2s;
  width: 4vh;
}

.down-arrow img:hover,
.up-arrow img:hover,
.home-arrow img:hover {
  background: #f24633;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .down-arrow img,
  .up-arrow img,
  .home-arrow img {
    display: block;
  }
}

@media (min-device-width: 1920px) {
  .down-arrow img,
  .up-arrow img
   {
    max-height: 54px;
  }

  .home-arrow img {
    max-width: 54px;
  }
}

@media (max-device-height: 480px) {
  .up-arrow,
  .down-arrow,
  .home-arrow {
    display: none;
  }
}
</style>
