<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Story.jpg')})`,
    }"
  >
    <div class="slide-container">
      <div class="story-items">
        <div><h3 class="subtitle">STORY</h3></div>

        <div class="story-content-container">
          <iframe
            class="story-frame"
            id="story-frame"
            src="/story"
            frameborder="0px"
          ></iframe>
        </div>
        <div class="statistics">
          <div class="statistics-container">
            <div><h3>10</h3></div>
            <div><p>SPACEMAN</p></div>
          </div>
          <div class="statistics-container">
            <div><h3>100</h3></div>
            <div><p>EXOPLANETS</p></div>
          </div>
          <div class="statistics-container">
            <div><h3>1000+</h3></div>
            <div><p>LIGHTYEARS</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "storySlide",
  data() {
    return {
      baseURL: process.env.BASE_URL,
    };
  },
  mounted() {
    window.frames["story-frame"].contentDocument.oncontextmenu = function () {
      return false;
    };
  },
};
</script>

<style>
.story-items {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.story-content-container {
  flex: 1 1 auto;
  width: 90vw;
}

.story-frame {
  width: 100%;
  height: 100%;
}

.statistics-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.statistics-container h3 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 4vh;
  margin-bottom: 10px;
  text-transform: none;
  color: #ffffff;
  margin-top: 0px;
}

.statistics {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.statistics-container p {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vh;
  line-height: 1em;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: none;
  color: #ffffff;
  font-weight: 500;
  margin-top: 0;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .statistics-container h3 {
    font-size: 6vh;
  }
  .statistics-container p {
    font-size: 2vh;
  }

  .statistics {
    padding: 0 20vw;
  }
}

@media (min-device-width: 1920px) {
  .statistics-container h3 {
    font-size: 64px;
  }
  .statistics-container p {
    font-size: 21px;
  }
}

@media (max-device-height: 480px) {
  .statistics-container h3 {
    font-size: 7vh;
  }
  .statistics-container p {
    font-size: 2vh;
  }

  .statistics {
    padding: 0 20vw;
  }
}
</style>
