<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Contact.jpg')})`,
    }"
  >
    <div class="slide-container">
      <div class="contact-content">
        <div class="contact-item"><h3 class="subtitle">CONTACT US</h3></div>
        <div class="contact-item">
          <div @click="openTwitter()">
            <img
              class="contact-picture"
              src="../../assets/icons/Twitter.png"
            />
          </div>
          <div @click="openDiscord()">
            <img
              class="contact-picture"
              src="../../assets/icons/Discord.png"
            />
          </div>
          <div>
            <a style="padding: 0" href="mailto:secretspacemannfts@gmail.com">
              <img
                class="contact-picture"
                src="../../assets/icons/Mail.png"
              />
            </a>
          </div>
        </div>
        <div class="contact-item"><h3 class="subtitle">VISIT MARKETPLACE</h3></div>
        <div class="contact-item">
          <div @click="openMarketplace()">
            <img
              class="contact-picture"
              src="../../assets/icons/Stashh.png"
            />
          </div>
        </div>
        <div class="contact-item">
          <p class="contact-subtext">
            Secret spaceman | 2022 | made with love by 10+ enthusiasts
          </p>
        </div>
        <div>
          <p class="contact-subtext">Copyright © 2022 SPACEMAN</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactSlide",
  methods: {
    openTwitter() {
      window.open(process.env.VUE_APP_TWITTER_URL);
    },
    openDiscord() {
      window.open(process.env.VUE_APP_DISCORD_URL);
    },
    openMarketplace() {
      window.open(process.env.VUE_APP_MARKETPLACE_URL);
    },
  },
};
</script>

<style>
.contact-content {
  display: flex;
  flex-direction: column;
}

.contact-item {
  display: flex;
  justify-content: center;
}

.contact-picture {
  padding: 20px;
  height: 5vh;
}

.contact-picture:hover {
  cursor: pointer;
  filter: invert(45%) sepia(88%) saturate(5423%) hue-rotate(346deg)
    brightness(104%) contrast(90%);
}

.contact-subtext {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vh;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}

@media screen and (min-device-width: 1025px) and (orientation: landscape) {
  .contact-subtext {
    font-size: 1.5vh;
  }

  .contact-picture {
    height: 6vh;
  }
}

@media (min-device-width: 1920px) {
  .contact-subtext {
    font-size: 14px;
  }
  .contact-picture {
    max-height: 65px;
    padding: 30px 30px 100px 30px;
  }
}

@media (max-device-height: 480px) {
  .contact-subtext {
    font-size: 2vh;
  }
  .contact-picture {
    max-height: 7vh;
  }
}
</style>
