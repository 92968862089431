<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Roadmap.jpg')})`,
    }"
  >
    <div class="roadmap-content">
      <div><h3 class="subtitle">ROADMAP</h3></div>
      <div>
        <p class="roadmap-subtext">Perks of a Secret Spaceman owner...</p>
      </div>
      <div class="roadmap-item">
        <img class="roadmap-image" src="../../assets/roadmap/Image.png" />
      </div>
      <div>
        <p class="roadmap-subtext" style="text-align: left; left: 20px">
          * Refer Whitepaper for more details...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roadmapSlide",
};
</script>

<style>
.roadmap-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.roadmap-subtext {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vh;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}

.roadmap-item {
  width: 90vw;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1vh;
}

.roadmap-image {
  height: 40vh;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .roadmap-subtext {
    font-size: 1.5vh;
  }

  .roadmap-image {
    height: 45vh;
  }
}

@media (min-device-width: 1920px) {
  .roadmap-subtext {
    font-size: 14px;
  }

  .roadmap-image {
    height: 486px;
  }
}
@media (max-device-height: 480px) {
  .roadmap-subtext {
    font-size: 2vh;
  }
}
</style>
