<template>
  <swiper
    @slideChange="onSlideChange"
    :direction="'vertical'"
    :slidesPerView="1"
    :mousewheel="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide><homeSlide /></swiper-slide>
    <swiper-slide><storySlide /></swiper-slide>
    <swiper-slide><spacemenSlide /></swiper-slide>
    <swiper-slide><collaborationsSlide /></swiper-slide>
    <swiper-slide><roadmapSlide /></swiper-slide>
    <swiper-slide><teamSlide /></swiper-slide>
    <swiper-slide><contactSlide /></swiper-slide>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import homeSlide from "./slides/HomeSlide.vue";
import storySlide from "./slides/StorySlide.vue";
import spacemenSlide from "./slides/SpacemenSlide.vue";
import collaborationsSlide from "./slides/CollaborationsSlide.vue";
import roadmapSlide from "./slides/RoadmapSlide.vue";
import teamSlide from "./slides/TeamSlide.vue";
import contactSlide from "./slides/ContactUsSlide.vue";
import "swiper/swiper-bundle.min.css";
import { Mousewheel } from "swiper";

export default {
  name: "mainframe",
  computed: {
    swiper() {
      return document.querySelector(".swiper").swiper;
    },
    emitter() {
      return require("tiny-emitter/instance");
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    homeSlide,
    storySlide,
    spacemenSlide,
    collaborationsSlide,
    roadmapSlide,
    teamSlide,
    contactSlide,
  },
  setup() {
    return {
      modules: [Mousewheel],
    };
  },
  mounted() {
    this.emitter.on("nav-slide", (index) => {
      this.swiper.slideTo(index);
    });
    this.emitter.on("goToHome", () => {
      this.swiper.slideTo(0);
    });
    this.emitter.on("slidePrev", () => {
      this.swiper.slidePrev();
    });
    this.emitter.on("slideNext", () => {
      this.swiper.slideNext();
    });
  },
  methods: {
    onSlideChange() {
      this.emitter.emit("slideChange", this.swiper.activeIndex);
    },
  },
};
</script>
