<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Spacemen.jpg')})`,
    }"
  >
    <div class="spaceman-content">
      <div><h3 class="subtitle">SPACEMAN</h3></div>
      <swiper
        @slideChange="onSlideChange"
        :direction="'horizontal'"
        :slidesPerView="1"
        :mousewheel="false"
        :pagination="{
          el: '.swiper-pagination',
          clickable: true,
        }"
        :navigation="{
          clickable: true,
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide
          style="background: none"
          v-for="item of items"
          :key="item.title"
        >
          <div class="spaceman-items">
            <div class="spaceman-item1">
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="spaceman-name">{{ item.subtitle }}</div>
              <div class="spaceman-title">{{ item.title }}</div>
            </div>
            <div class="spaceman-item2">
              <img
                class="spaceman-image"
                :src="this.front ? item.urlFront : item.urlBack"
                alt="{{item.title}}"
              />
            </div>
            <div class="spaceman-item3">
              <div class="spaceman-description">{{ item.description }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="button-container">
        <div class="buttonf" @click.prevent="switchSide(true)">FRONT</div>
        <div class="buttonb" @click.prevent="switchSide(false)">BACK</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Pagination, Navigation } from "swiper";

export default {
  name: "spacemenSlide",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      front: true,
      items: [
        {
          title: "Explorer",
          subtitle: "MSO C-I",
          urlFront: require("../../assets/spaceman/ExplorerFront.png"),
          urlBack: require("../../assets/spaceman/ExplorerBack.png"),
          description:
            "A Spaceman who will help you conquer your curiosity for exploring. With a rigid body made suitable for adapting to softer and non-toxic terrains, he will be your perfect aid to explore planets that are more likely to mimic Earth in their appearance and composition.",
        },

        {
          title: "Hazmat",
          subtitle: "MSO C-II",
          urlFront: require("../../assets/spaceman/HazmatFront.png"),
          urlBack: require("../../assets/spaceman/HazmatBack.png"),
          description:
            "Hazmats are for the daredevils venturing out to investigate exoplanets roaring with severe dust storms or ones that have poisonous and toxic fumes emanating from their surfaces. They have super-ultra low particulate air filters woven into the skin of their suits making them highly efficient against unsafe gases, dust and minuscule volcanic dust particles.",
        },
        {
          title: "Exoskeleton",
          subtitle: "MSO C-III",
          urlFront: require("../../assets/spaceman/ExoskeletonFront.png"),
          urlBack: require("../../assets/spaceman/ExoskeletonBack.png"),
          description:
            "A suit that powers you to wander on planets that have increased gravity. The suit is built using advanced neo-minerals and is powered with the help of an electro-linen lining under the skin of the suit. Combined, they make it easier to adapt to surfaces where the gravitational pull is significantly higher than Earth.",
        },
        {
          title: "Thruster",
          subtitle: "MSO C-IV",
          urlFront: require("../../assets/spaceman/ThrustersFront.png"),
          urlBack: require("../../assets/spaceman/ThrustersBack.png"),
          description:
            "The thruster is your go-to Spaceman when exploring giant gaseous planets that have no true surface. Internally powered by an enhanced energy source, the artificial wing mechanism will let you glide through the atmosphere while the suit's make-up ensures that you explore it unscathed.",
        },
        {
          title: "Miner",
          subtitle: "MSO C-V",
          urlFront: require("../../assets/spaceman/MinerFront.png"),
          urlBack: require("../../assets/spaceman/MinerBack.png"),
          description:
            "A Spaceman designed exclusively to help you drill your way through. Equipped with an advanced and sturdy drill bit that can make its way through any surface, Miner will assist you in digging for minerals, riches and much more in your exoplanet exploration.",
        },
        {
          title: "Luminescent",
          subtitle: "MSO C-VI",
          urlFront: require("../../assets/spaceman/LuminescentFront.png"),
          urlBack: require("../../assets/spaceman/LuminescentBack.png"),
          description:
            "Traversing through surfaces that have extremely low visibility can be a challenging task. The thoughtful design of Luminescent Spaceman consists of an augmented illumination tech that can adapt its intensity, colour and pattern smartly based on the severity of the surroundings and necessity.",
        },
        {
          title: "Armour",
          subtitle: "MSO C-VII",
          urlFront: require("../../assets/spaceman/ArmourFront.png"),
          urlBack: require("../../assets/spaceman/ArmourBack.png"),
          description:
            "Our Choice of Spaceman when we venture into hitherto unknown boundaries with a distinct hint of alien presence. The sophisticated and supreme suit of Armour warrants your safety from any potential hazardous and hostile alien/other world-ly technology that you might encounter in your journey.",
        },
        {
          title: "Climber",
          subtitle: "MSO C-VIII",
          urlFront: require("../../assets/spaceman/ClimberFront.png"),
          urlBack: require("../../assets/spaceman/ClimberBack.png"),
          description:
            "You could always use an extra set of limbs while doing chores-especially on a foreign planet. Climber’s telescoping, thought-controlled, prehensile titanium-adamantine arms are attached to the built-in super-light harness to give you the extra support and mobility with ease. Climbers are suited for planets that have a severely rugged terrain made up of ridges, canyons and valleys.",
        },
        {
          title: "Underwater",
          subtitle: "MSO C-IX",
          urlFront: require("../../assets/spaceman/UnderwaterFront.png"),
          urlBack: require("../../assets/spaceman/UnderwaterBack.png"),
          description:
            "A highly complex Spaceman to explore planetary surfaces that are either partially or entirely submerged. The suit’s dynamic thermal system will automatically adjust to maintain optimum body temperature giving you the perfect comfort to move around, even when fully surrounded by fluids.",
        },
        {
          title: "Designer",
          subtitle: "MSO C-X",
          urlFront: require("../../assets/spaceman/DesignersFront.png"),
          urlBack: require("../../assets/spaceman/DesignersBack.png"),
          description:
            "They are the pioneers piloting the journey. The Designer is accompanied by his all-inclusive Spaceship carrying explorers and futuristic equipments, capable of fabricating an artificial atmosphere required during exploration.",
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  methods: {
    onSlideChange() {
      this.front = true;
    },
    //switches spaceman front and back
    switchSide(value) {
      this.front = value;
    },
  },
};
</script>

<style scoped>
.swiper {
  height: 70vh;
}

.spaceman-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.spaceman-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80vw;
  height: 60vh;
}

.spaceman-name {
  font-family: Expansiva;
  font-style: normal;
  font-size: 2vh;
  color: #ffffff;
  text-align: left;
}

.spaceman-title {
  font-family: Expansiva;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vh;
  color: #ffffff;
  text-align: left;
}

.spaceman-item1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 30%;
}
.spaceman-item2 {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
}

.spaceman-item3 {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 30%;
}

.spaceman-image {
  width: 40vh;
}

.spaceman-description {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 1.7vh;
  line-height: 2vh;
  text-align: justify;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.button-container {
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonf {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 900;
  font-size: 1.7vh;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  background: #ffffff;
  padding: 5px 10px;
  cursor: pointer;
  border: 2px solid #ffffff;
}

.buttonb {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 900;
  font-size: 1.7vh;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  padding: 5px 10px;
  border: 2px solid #ffffff;
}

.buttonf:hover,
.buttonb:hover {
  background-color: #f24633;
  color: #ffffff;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .swiper {
    height: 60vh;
  }

  .buttonf,
  .buttonb {
    font-size: 1.8vh;
  }

  .spaceman-items {
    flex-direction: row;
    width: 80vw;
    height: 60vh;
  }

  .spaceman-item1 {
    align-items: self-end;
  }

  .spaceman-name {
    font-size: 3vh;
  }

  .spaceman-title {
    font-size: 4vh;
  }

  .spaceman-image {
    height: 50vh;
    width: auto;
  }

  .spaceman-description {
    font-size: 2vh;
    line-height: 3vh;
  }
}

@media (min-device-width: 1920px) {
  .swiper {
    height: 650px;
  }
  .buttonf,
  .buttonb {
    font-size: 24px;
    padding: 20px;
  }

  .spaceman-name {
    font-size: 32px;
  }

  .spaceman-title {
    font-size: 44px;
  }

  .spaceman-image {
    max-height: 540px;
  }

  .spaceman-description {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-device-height: 480px) {
  .swiper {
    height: 60vh;
  }
  .buttonf,
  .buttonb {
    font-size: 3vh;
    padding: 3px 7px;
  }

  .spaceman-name {
    font-size: 3vh;
  }

  .spaceman-title {
    font-size: 4vh;
  }

  .spaceman-item1 {
    flex: 0;
  }

  .spaceman-item3 {
    display: none;
  }

  .spaceman-image {
    height: 50vh;
    width: auto;
  }
}
</style>
