<template>
  <div
    class="slide-bg"
    :style="{
      backgroundImage: `url(${require('../../assets/backgrounds/Collaborations.png')})`,
    }"
  >
    <div class="slide-container">
      <div class="collab-items">
        <div><h3 class="subtitle">COLLABORATIONS</h3></div>
        <div class="collab-container">
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://altermail.live/"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/alter.png"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://www.ibcnfts.com/"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/ibcNfts.png"
              />
            </a>
          </div>
          <div class="collab-team">
            <img
              class="collab-dp"
              src="../../assets/icons/collabIcons/spacecorp.png"
            />
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://twitter.com/IBCSuperNauts"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/ibcSupernauts.png"
                style="mix-blend-mode: lighten"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://www.midnightteddyclub.art/"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/mtc.png"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://twitter.com/CyberAgents_NFT"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/cyberAgents.png"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://twitter.com/CosmoVoyagers"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/cosmoVoyagers.png"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://trivium.network/"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/trivium.png"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://copernicspace.com/"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/copernicSpace.png"
              />
            </a>
          </div>
          <div class="collab-team link">
            <a
              style="padding: 0; text-decoration: none"
              href="https://twitter.com/DJzofDGEN"
              target="_blank"
            >
              <img
                class="collab-dp"
                src="../../assets/icons/collabIcons/dod.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "collaborationsSlide",
};
</script>

<style>
.collab-items {
  display: flex;
  flex-direction: column;
}

.collab-dp {
  margin: 10px;
  height: 5vh;
}

.collab-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.collab-team {
  margin: 10px;
  text-align: center;
  font-size: 30px;
  background: rgba(196, 196, 196, 0.01);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  flex: 1 0 15%;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.collab-team.link:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .collab-dp {
    height: 7vh;
  }
}

@media (min-device-width: 1920px) {
  .collab-dp {
    max-height: 80px;
    margin: 15px;
  }
}

@media (max-device-height: 480px) {
  .collab-dp {
    height: 10vh;
    margin: 5px;
  }
}
</style>
