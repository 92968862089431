import { createWebHistory, createRouter } from "vue-router";
import home from "@/views/HomeView.vue";
import story from "@/views/StoryView.vue";
import claim from "@/views/ClaimView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: home,
  },
  {
    path: "/story",
    name: "Story",
    component: story,
  },
  {
    path: "/claim",
    name: "Claim",
    component: claim,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
