<template>
  <div>
    <div
      class="slide-bg"
      v-show="loadingScreen === true"
      :style="{
        backgroundImage: `url(${require('../assets/backgrounds/Home.jpg')})`,
      }"
    >
      <div
        class="loading-bg"
        :style="{
          backgroundImage: `url(${require('../assets/backgrounds/LoadingScreen.png')})`,
        }"
      >
        <div class="loading-content">
          <VueCountdown
            v-show="hideCountdown === false"
            :time="3 * 1000"
            v-slot="{ seconds }"
            class="subtitle"
            style="margin-top: 35vh"
          >
            T - {{ seconds }}
          </VueCountdown>
        </div>
      </div>
    </div>
    <div v-show="loadingScreen === false" id="home">
      <appHeader />
      <appFooter />
      <mainframe style="margin-top: -50px" />
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import mainframe from "../components/Mainframe.vue";
import appHeader from "../components/Header.vue";
import appFooter from "../components/Footer.vue";

export default {
  name: "App",
  data() {
    return {
      loadingScreen: true,
      hideCountdown: false,
    };
  },
  components: {
    mainframe,
    appHeader,
    appFooter,
    VueCountdown,
  },
  mounted() {
    setTimeout(() => (this.loadingScreen = false), 3750);
    setTimeout(() => (this.hideCountdown = true), 2000);
  },
};
</script>

<style>
@font-face {
  font-family: Expansiva;
  src: url("../assets/fonts/Expansiva.otf") format("opentype");
}

@keyframes loadingscreen {
  from {
    height: 100vh;
  }
  to {
    height: 0vh;
  }
}

.loading-bg {
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  animation-name: loadingscreen;
  animation-duration: 2s;
  animation-delay: 2s;
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
