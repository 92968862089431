<template>
  <div class="full-story">
    <p class="story-para" style="font-weight: 500">Spaceman - The beginning</p>

    <p class="story-para" style="font-weight: 500">Year - 2150.</p>

    <p class="story-para">
      Humanity has made tremendous technological advancements in space
      exploration, health and science. Advancements in space exploration led to
      the colonization of Mars at the end of the 21st century. As initial
      colonies began to settle, a group of highly intelligent humans created the
      Martian Space Organization (MSO) to facilitate immense research on
      advanced fields on Martian soil.
    </p>

    <p class="story-para">Two such types of research were game-changers.</p>

    <p class="story-para">
      - Research on the effects of the Martian atmosphere and gravity on the
      human body resulted in the invention of sophisticated spacesuits.
    </p>

    <p class="story-para">
      - Asteroid mining technology was developed tremendously for extracting
      precious minerals from the asteroid belt.
    </p>

    <p class="story-para">
      On one such mining expedition, humanity stumbled upon several minerals
      with the advanced ability to hold and radiate power. Combining these
      minerals and biological knowledge, humanity created advanced spacesuits
      capable of sustaining any exoplanet surfaces.
    </p>

    <p class="story-para">
      With the newly found spacesuits and advancements in technology, a group of
      scientists from the Martian Space Organisation (MSO) put together a team
      of 100 spacemen for an expedition mission to 100 different exo-surfaces
      (consisting of exomoons and exoplanets) to spread humanity to the known
      corners of the universe. The potential life harbouring exo-surfaces were
      selected from the data of the Hawking telescope launched in the early
      decade of the 22nd century.
    </p>

    <p class="story-para">
      In total, 10 advanced spacesuits were developed for this mission. Each
      spacesuit is suitable for a category of the planet. Their mission is to
      land on the surface and study the planet's climate and life harbouring
      factors. The spacemen from the different exo-surfaces must send back their
      data to MSO in the form of an encrypted image on the secret network for
      the scientists to decrypt and to study in detail about the exo-surfaces.
    </p>
  </div>
</template>

<script>
export default {
  name: "story",
};
</script>

<style>
.story-para {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vh;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: none;
  padding: 0 20px;
  color: #ffffff;
  flex: 1 1 auto;
}

.full-story {
  height: 220px;
  position: absolute;
  justify-content: center;
  margin: 0 20px;
  margin-bottom: 50px;
}

@media (min-device-width: 1025px) and (orientation: landscape) {
  .story-para {
    font-size: 4.5vh;

    font-weight: 300;
  }
}

@media (min-device-width: 1920px) {
  .story-para {
    font-size: 27px;
  }
}

@media (max-device-height: 480px) {
  .story-para {
    font-size: 5vh;
  }
}
</style>
